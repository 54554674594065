<style lang="scss" scoped></style>

<template>
  <div class="wrap">
    <el-form inline size="mini">
      <el-form-item label="Date:">
        <date-range
          :utc4="isOutright ? 0 : 1"
          :isOutright="isOutright"
          type="datetimerange"
          v-model="dateRange"
          @change="dateRangeFn"
        />
      </el-form-item>
      <el-form-item label="Sport:">
        <!-- <input-autocomplete
          @change="e => extraData.sportId = e.label"
          search-key="STD_SEARCH_SPORT"
          :queryOrderParams="{ page: 1, size: 500 }"
          value-key="sportsName"
          label-key="id"
        /> -->
        <select-box
          :url="STD_SEARCH_SPORT"
          :queryOrderParams="{ page: 1, size: 500 }"
          :value.sync="extraData.sportId"
          :remote="false"
          placeholder="Select"
          label-key="sportsName"
          value-key="id"
        />
      </el-form-item>
      <el-form-item label="Region:">
        <input-autocomplete
          :extraData.sync="extraData.sourceRegionName"
          :queryOrderParams="{ page: 1, size: 500, deleteFlag: 1 }"
          search-key="STD_SEARCH_REGION"
          value-key="name"
          label-key="id"
          query-key="regionName"
        />
      </el-form-item>
      <el-form-item label="Tournament:">
        <input-autocomplete
          :extraData.sync="extraData.sourceTournamentName"
          search-key="STD_SEARCH_TOURNAMENT"
          :queryOrderParams="{ page: 1, size: 100 }"
          value-key="name"
          label-key="id"
          query-key="name"
        />
      </el-form-item>
      <el-form-item label="Team:" v-if="!isOutright">
        <!-- <select-box
          :url="STD_SEARCH_TEAM"
          :queryOrderParams="{ page: 1, size: 500 }"
          :value.sync="extraData.sourceTeamName"
          placeholder="Select"
          search-key="name"
          label-key="name"
          value-key="name"
        /> -->
        <input-autocomplete
          :extraData.sync="extraData.sourceTeamName"
          search-key="STD_SEARCH_TEAM"
          :queryOrderParams="{ page: 1, size: 500 }"
          value-key="name"
          label-key="name"
          query-key="name"
        />
      </el-form-item>
      <el-form-item label="Source of Data:">
        <select-box
          width="200"
          :multiple="true"
          :options="originData"
          :value.sync="extraData.dataSourceCodes"
          placeholder="Please select source of data"
        />
      </el-form-item>
      <el-form-item label="Mapped Status:">
        <select-box
          width="140"
          :options="statusOptions"
          :value.sync="extraData.status"
        />
      </el-form-item>
      <el-form-item label="Check Status:">
        <select-box
          width="140"
          :options="checkOptions"
          :value.sync="extraData.checkStatus"
        />
      </el-form-item>
      <el-form-item label="Source ID:">
        <el-input
          v-model="extraData.sourceMatchId"
          clearable
          placeholder="Please enter source id"
        ></el-input>
      </el-form-item>
      <el-form-item label="Match Status:" v-if="!isOutright">
        <el-select
          clearable
          v-model="extraData.matchStatus"
          placeholder="Please typing in keyword and select"
        >
          <el-option
            v-for="item in matchStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Ignore" v-if="!isOutright">
        <el-select v-model="extraData.ignore" placeholder="" clearable="">
          <el-option label="Yes" :value="1"></el-option>
          <el-option label="No" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Player Makret" v-if="!isOutright">
        <el-select v-model="extraData.playerMarket" placeholder="" clearable="">
          <el-option label="Yes" :value="true"></el-option>
          <el-option label="No" :value="false"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
      </el-form-item>
    </el-form>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in tabsList"
        :key="index"
        :label="item.label + '(' + item.total + ')'"
        :name="item.value"
      ></el-tab-pane>
    </el-tabs>
    <el-table
      :data="list"
      v-loading="loading"
      @sort-change="sort"
      :max-height="tableHeight - 50"
      @cell-mouse-enter="cellEnterHover"
      @cell-mouse-leave="cellLeaveHover"
      :row-class-name="tableRowClassName"
    >
      <el-table-column
        label="Match ID"
        width="180"
        align="center"
        prop="standardMatchId"
        sortable="custom"
      >
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.matchIdStatus"
            :class="scope.row.mappingStatus ? 'red' : 'blue'"
            v-model="scope.row.standardMatchId"
            placeholder="Input"
            @blur="inputBlur(scope.$index, scope.row)"
          ></el-input>
          <p v-else>{{ scope.row.standardMatchId }}</p>
        </template>
      </el-table-column>
      <el-table-column
        label="source ID"
        min-width="110"
        align="center"
        prop="sourceMatchId"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        label="Source of Data"
        width="140"
        align="center"
        prop="dataSourceCode"
      >
        <template v-slot="{ row }">
          {{ originMapping[row.dataSourceCode] }}
        </template>
      </el-table-column>
      <el-table-column
        label="Sport"
        align="center"
        prop="sourceSportsName"
        min-width="120"
      >
        <template v-slot="{ row }">
          <p
            :class="{
              red: row.getUnMappedErrorStatus && !row.sportMappedStatus
            }"
          >
            <tag-id
              class="cursor"
              :value="`${row.sourceSportsName}ID:${row.sourceSportId}`"
            />
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="Date"
        width="170"
        align="center"
        prop="beginTime"
        v-if="!isOutright"
        sortable="custom"
      >
        <template v-slot="{ row }">{{
          parseTime(row.beginTime, '{y}-{m}-{d} {h}:{i}:{s}')
        }}</template>
      </el-table-column>
       <el-table-column
        label="Start Time"
        width="170"
        align="center"
        prop="beginTime"
        v-if="isOutright"
        sortable="custom"
      >
        <template v-slot="{ row }">{{
          parseTime(row.beginTime, '{y}-{m}-{d} {h}:{i}:{s}')
        }}</template>
      </el-table-column>
            <el-table-column
        label="End Time"
        width="170"
        align="center"
        prop="beginTime"
        v-if="isOutright"
        sortable="custom"
      >
        <template v-slot="{ row }">{{
          parseTime(row.endTime, '{y}-{m}-{d} {h}:{i}:{s}')
        }}</template>
      </el-table-column>
      <el-table-column
        label="Region"
        align="center"
        prop="sourceRegionName"
        min-width="130"
      >
        <template v-slot="{ row }">
          <p
            :class="{
              red: row.getUnMappedErrorStatus && !row.regionMappedStatus
            }"
          >
            <tag-id
              class="cursor"
              @click.native="edit(row, 'Region')"
              :value="`${row.sourceRegionName}ID:${row.sourceRegionId}`"
            />
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="Tournament"
        align="center"
        prop="sourceTournamentName"
        min-width="200"
      >
        <template v-slot="{ row }">
          <p
            :class="{
              red: row.getUnMappedErrorStatus && !row.tournamentMappedStatus
            }"
          >
            <tag-id
              class="cursor"
              @click.native="edit(row, 'Tournament')"
              :value="`${row.sourceTournamentName}ID:${row.sourceTournamentId}`"
            />
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="Match"
        align="center"
        prop="sourceTournamentName"
        min-width="200"
        v-if="isOutright"
      >
        <template v-slot="{ row }">
          {{ row.name || row.sourceTournamentName }} {{ row.year }}
        </template>
      </el-table-column>
      <el-table-column
        label="Home"
        align="center"
        prop="sourceHome"
        min-width="160"
        v-if="!isOutright"
        sortable
      >
        <template v-slot="{ row }">
          <p
            :class="{
              red: row.getUnMappedErrorStatus && !row.homeMappedStatus
            }"
          >
            <tag-id
              class="cursor"
              @click.native="edit(row, 'Home')"
              :value="`${row.sourceHome}ID:${row.sourceHomeId}`"
            />
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="Away"
        align="center"
        prop="sourceAway"
        min-width="168"
        v-if="!isOutright"
        sortable
      >
        <template v-slot="{ row }">
          <p
            :class="{
              red: row.getUnMappedErrorStatus && !row.awayMappedStatus
            }"
          >
            <tag-id
              class="cursor"
              @click.native="edit(row, 'Away')"
              :value="`${row.sourceAway}ID:${row.sourceAwayId}`"
            />
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="Market Mapping"
        v-if="isOutright"
        width="160"
        align="center"
        prop="matchStatusText"
      >
        <template v-slot="{ row }">
          <el-button
            v-if="+row.dataSourceCode === 2"
            type="text"
            @click="marketMappingFn(row)"
            :disabled="!row.standardMatchId"
            >Mapping</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        label="Match Status"
        v-if="!isOutright"
        width="160"
        align="center"
        prop="matchStatusText"
      >
        <template slot-scope="scope">
          <p :class="{matchTips: ![0,4,5].includes(+scope.row.matchStatus)}">
            {{
              matchStatusList[scope.row.matchStatus] &&
              matchStatusList[scope.row.matchStatus].label
            }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="Match Format"
        v-if="!isOutright"
        width="160"
        align="center"
        prop="matchStatusText"
      >
        <template slot-scope="scope">
          <p>{{ formatList[scope.row.matchFormat] }}</p>
        </template>
      </el-table-column>
      <el-table-column
        label="Mapped Status"
        align="center"
        prop="shortName"
        width="157"
      >
        <template v-slot="{ row }">
          <p
            :class="{
              red: +row.mappingStatus === 2,
              green: [0, 1].includes(row.mappingStatus)
            }"
          >
            • {{ mappingStatus[row.mappingStatus] || 'Unmapping' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="Reverse" v-if="!isOutright">
        <template v-slot="{ row }">
          <el-switch
            v-model="row.isReverse"
            :disabled="row.mappingStatus !== 1"
            :active-value="1"
            :inactive-value="0"
            @change="changeReverse(row, $event)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="Neutral"
        min-width="140"
        align="center"
        v-if="!isOutright"
      >
        <template v-slot="{ row }">
          {{ row.neutralGround ? 'Yes' : 'No' }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="Reverse" align="center" prop="shortName"  v-if="!isOutright">
        <template v-slot="{ row }">
          <p class="red" v-if="Number(row.reverse)">R</p>
        </template>
      </el-table-column> -->
      <el-table-column
              label="Player"
              min-width="120"
              align="center"
              v-if="!isOutright"
            >
              <template v-slot="{ row }">
                <el-link :disabled="!row.playerMarket" type="primary" @click="linkEdit(row)">Edit</el-link>
                <!-- {{ row.neutralGround ? 'Yes' : 'No' }} -->
              </template>
            </el-table-column>
      <el-table-column label="Ignore" v-if="!isOutright">
        <template v-slot="{ row }">
          <el-switch
            v-model="row.ignore"
            :disabled="!!row.standardMatchId"
            :active-value="1"
            :inactive-value="0"
            @change="changeIgnore(row, $event)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="Check Status" min-width="150" align="center">
        <template v-slot="{ row }">
          <div class="check-status-wrap">
            <el-switch
              :value="row.switchStatus"
              :active-value="true"
              :inactive-value="false"
              @change="switchHandle(row)"
            ></el-switch>

            <status-icon
              v-if="row.checkStatus"
              :status="checkStatusTexts[row.checkStatus]"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="Remark"
        min-width="180"
        align="center"
        prop="remark"
      >
        <template v-slot="{ row }">
          <dropdown
            :row="{
              thirdMatchId: row.thirdMatchId
            }"
            :methodStr="methodStr"
            :text="`${row.remark || 'Please select'}`"
            @get-list="getList"
            :options="MatchMappingRemarkOptions"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Operation"
        align="center"
        prop="shortName"
        min-width="120"
      >
        <template v-slot="{ row }">
          <el-link type="primary" :underline="false" @click="before(row)"
            >Mapping</el-link
          >
          <el-link type="danger" :underline="false" @click="createMatch(row)"
            >Create</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="Log" min-width="160" align="center">
        <template v-slot="{ row }">
          <OperationType :dataType="'Third_Match'" :dataId="row.thirdMatchId" />
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin: 20px 0 0 0">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>
    <el-dialog
      :title="`${
        type === 'Home' || type === 'Away' ? 'Team' : type
      } Mapping Edit`"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
    >
      <el-form
        :model="dialogForm"
        label-width="164px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item label="Source Name:">
          {{ dialogForm.thirdName }}
        </el-form-item>
        <el-form-item
          v-if="type === 'Sport'"
          label="Standard Name:"
          prop="standardId"
          required
        >
          <select-box
            :url="STD_SEARCH_SPORT"
            :queryOrderParams="{ page: 1, size: 500 }"
            :value.sync="dialogForm.standardId"
            :label="dialogForm.standardName"
            search-key="name"
            label-key="sportsName"
            value-key="id"
          />
        </el-form-item>
        <el-form-item
          v-if="type === 'Region'"
          label="Standard Name:"
          prop="standardId"
          required
        >
          <select-box
            :url="STD_SEARCH_REGION"
            :queryOrderParams="{ page: 1, size: 500, deleteFlag: 1 }"
            :value.sync="dialogForm.standardId"
            :label="dialogForm.standardName"
            search-key="name"
            label-key="name"
            value-key="id"
          />
        </el-form-item>
              <el-form-item
          v-if="type === 'Tournament'"
          label="Standard Sport:"
        >
          <select-box
            :url="STD_SEARCH_SPORT"
            :queryOrderParams="{ page: 1, size: 500 }"
            :value.sync="dialogForm.sportId"
            :label="dialogForm.standardSportsName"
            search-key="name"
            label-key="sportsName"
            value-key="id"
          />
        </el-form-item>
        <el-form-item
          v-if="type === 'Tournament'"
          label="Standard Region:"
          prop="regionId"
        >
          <select-box
            :url="STD_SEARCH_REGION"
            :queryOrderParams="{
              page: 1,
              size: 500,
              sportId: dialogForm.sportId,
              deleteFlag: 1
            }"
            :value.sync="dialogForm.regionId"
            :label="dialogForm.regionName"
            search-key="name"
            label-key="name"
            value-key="id"
            @change="onSelectedDrug"
          />
        </el-form-item>
        <el-form-item
          v-if="type === 'Tournament'"
          label="Standard Name:"
          prop="standardId"
        >
          <select-box
            :url="STD_SEARCH_TOURNAMENT"
            :queryOrderParams="{
              page: 1,
              size: 100,
              sportId: dialogForm.sportId,
              regionId: dialogForm.regionId,
              isVisible: 1
            }"
            :value.sync="dialogForm.standardId"
            :label="dialogForm.standardName"
            search-key="name"
            label-key="name"
            value-key="id"
          />
        </el-form-item>
        <el-form-item
          v-if="type === 'Home' || type === 'Away'"
          label="Standard Sport:"
          prop="standardSportId"
          required
        >
          <select-box
            :url="STD_SEARCH_SPORT"
            :queryOrderParams="{ page: 1, size: 500 }"
            :value.sync="dialogForm.standardSportId"
            :label="dialogForm.standardSportsName"
            search-key="name"
            label-key="sportsName"
            value-key="id"
          />
        </el-form-item>
        <el-form-item
          v-if="type === 'Home' || type === 'Away'"
          label="Standard Region:"
          prop="standardRegionId"
        >
          <select-box
            :url="STD_SEARCH_REGION"
            :queryOrderParams="{
              page: 1,
              size: 500,
              sportId: dialogForm.standardSportId,
              deleteFlag: 1
            }"
            :value.sync="dialogForm.standardRegionId"
            :label="dialogForm.standardRegionName"
            search-key="name"
            label-key="name"
            value-key="id"
            @change="onSelectedDrug"
          />
        </el-form-item>
        <el-form-item
          v-if="type === 'Home' || type === 'Away'"
          label="Standard tournament:"
          prop="standardTournamentId"
        >
          <select-box
            :url="STD_SEARCH_TOURNAMENT"
            :queryOrderParams="{
              page: 1,
              size: 100,
              sportId: dialogForm.standardSportId,
              isVisible: 1
            }"
            :value.sync="dialogForm.standardTournamentId"
            :label="dialogForm.standardTournamentName"
            search-key="name"
            label-key="name"
            value-key="id"
            @change="onSelectedDrug"
          />
        </el-form-item>
        <el-form-item
          v-if="type === 'Home' || type === 'Away'"
          label="Standard Name:"
          prop="standardId"
        >
          <select-box
            :url="STD_SEARCH_TEAM"
            :queryOrderParams="{
              page: 1,
              size: 100,
              tournamentId: dialogForm.standardTournamentId,
              regionId: dialogForm.standardRegionId,
              sportId: dialogForm.standardSportId,
              isVisible: 1
            }"
            :value.sync="dialogForm.standardId"
            :label="dialogForm.standardName"
            search-key="name"
            label-key="name"
            value-key="id"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="dialogSubmit"
            :loading="dialog.isLoading"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      title="Please Check the creation and mapping"
      :visible.sync="dialogTableVisible"
    >
      <el-table :data="gridData">
        <el-table-column
          property="dataSourceCode"
          label="Source Data"
          width="130"
        >
          <template v-slot="{ row }">
            {{ originMapping[row.dataSourceCode] || 'Standard Match' }}
          </template>
        </el-table-column>
        <el-table-column property="id" label="Match ID" width="120">
          <template v-slot="{ row }">
            {{ row.sourceMatchId || row.id }}
          </template>
        </el-table-column>
        <el-table-column property="sportName" label="Sport" width="120">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">{{ scope.row.sportName }}</p>
            <p
              v-else
              :style="
                thirdCompared(gridData[0].sportName, scope.row.sourceSportsName)
              "
            >
              {{ scope.row.sourceSportsName }}
            </p>
          </template>
        </el-table-column>
        <el-table-column property="beginTime" label="Date" width="200">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">
              {{ parseTime(scope.row.beginTime, '{y}-{m}-{d} {h}:{i}:{s}') }}
            </p>
            <p
              v-else
              :style="thirdCompared(gridData[0].beginTime, scope.row.beginTime)"
            >
              {{ parseTime(scope.row.beginTime, '{y}-{m}-{d} {h}:{i}:{s}') }}
            </p>
          </template>
        </el-table-column>
        <el-table-column property="regionName" label="Region" width="120">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">{{ scope.row.regionName }}</p>
            <p
              v-else
              :style="
                thirdCompared(
                  gridData[0].regionName,
                  scope.row.sourceRegionName
                )
              "
            >
              {{ scope.row.sourceRegionName }}
            </p>
          </template>
        </el-table-column>
        <el-table-column property="tournament" label="Tournament" width="200">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">{{ scope.row.tournament }}</p>
            <p
              v-else
              :style="
                thirdCompared(
                  gridData[0].tournament,
                  scope.row.sourceTournamentName
                )
              "
            >
              {{ scope.row.sourceTournamentName }}
            </p>
          </template>
        </el-table-column>
        <el-table-column property="home" label="Home Team" width="200">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">{{ scope.row.home }}</p>
            <p
              v-else
              :style="thirdCompared(gridData[0].home, scope.row.sourceHome)"
            >
              {{ scope.row.sourceHome }}
            </p>
          </template>
        </el-table-column>
        <el-table-column property="away" label="Away Team" width="200">
          <template slot-scope="scope">
            <p v-if="scope.$index === 0">{{ scope.row.away }}</p>
            <p
              v-else
              :style="thirdCompared(gridData[0].away, scope.row.sourceAway)"
            >
              {{ scope.row.sourceAway }}
            </p>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="create">Create</el-button>
        <el-button @click="dialogTableVisible = false">Cancel</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMatchMappingList as getData,
  editTeamMapping,
  editSportMapping,
  editRegionMapping,
  editTournamentMapping,
  getMatchHighlight,
  againMapping,
  eidtMatchMappingCheckStatus,
  updateSetMatchInfo,
  findMatchInfoCount,
  queryThirdTeamByIds,
  queryThirdTournamentBySourceIds,
  queryThirdRegionBySourceIds,
  // queryThirdSportTypeBySourceIds,
  getStandardMatch,
  createStandardMatchFromThirdMatch,
  getMappingThirdMatchCounter,
  updateIgnore,
  updateReverse
} from '@/service/subscribe'
import { matchFormatList } from '@/service/standard'
import { getAfterDate, parseTime, getDataTimeAfterDate } from '@/utils'
import TableMixin from '@/mixins/subscibe/table'
import DateRange from '@/components/DateRange/dateRange'
import Dropdown from '@/components/Dropdown'
import InputAutocomplete from '@/components/InputAutocomplete'
import { MatchMappingRemarkOptions, matchStatusList } from '@/utils/constants'
import StatusIcon from '@/components/StatusIcon'
import { mapGetters } from 'vuex'
import TagId from '@/components/TagId'
import { mappingStatus } from '@/utils/constants'

const toDay = getDataTimeAfterDate()

export default {
  name: 'MatchMapping',
  mixins: [TableMixin],
  components: {
    DateRange,
    Dropdown,
    InputAutocomplete,
    StatusIcon,
    TagId
  },
  beforeCreate() {
    this.timerGroup = {}
  },
  data() {
    return {
      mappingStatus,
      MatchMappingRemarkOptions,
      matchStatusList,
      formatList: {},
      dateRange: [...toDay],
      extraData: {
        startTime: toDay[0],
        endTime: toDay[1],
        ignore: 0
      },
      type: '',
      queryData: {
        size: 100,
        current: 1,
        orders: [
          {
            asc: true,
            column: 'begin_time'
          }
        ]
      },
      methodStr: 'eidtMatchReamrk',
      checkStatusTexts: {
        1: 'success',
        2: 'warning',
        3: 'error'
      },
      gridData: [],
      dialogTableVisible: false,
      activeName: '0',
      tabsList: [
        { label: 'All', value: '0', total: 0 },
        { label: 'SR', value: '2', total: 0 },
        { label: 'TX', value: '1', total: 0 },
        { label: 'SR(T)', value: '3', total: 0 },
        // { label: 'BetConstruct', value: '4', total: 0 },
        { label: 'LS', value: '5', total: 0 },
        { label: 'RB(T)', value: '6', total: 0 },
        { label: 'BG(T)', value: '8', total: 0 },
        { label: 'Kospt', value: '18', total: 0 },
        { label: 'IPTV', value: '9', total: 0 },
        { label: 'IMG', value: '23', total: 0 },
        { label: 'NaMi', value: '10', total: 0 },
      ],
      isOutright: '',
      matchType: 2
    }
  },
  created() {
    this.isOutright = /outright/.test(this.$route.path.toLowerCase())
    this.matchType = this.isOutright ? 1 : 2
    const status = this.$route.query.status
    if (status) {
      this.extraData.status = +status
    }
    if (!this.isOutright) {
      matchFormatList().then((res) => {
        if (res.code === 0) {
          const formatObj = {}
          res.data.map((item) => {
            formatObj[item.code] = item.value
          })
          this.formatList = formatObj
        }
      })
    }
  },
  activated() {
    if (this.active) {
      this.getList()
    }
    this.active = true
  },
  computed: {
    ...mapGetters(['userInfo', 'functionInfoList'])
  },
  methods: {
    linkEdit(row) {
     const { href } = this.$router.resolve({
        path: '/playerMarkets',
        query: row
      })
      window.open(href, '_blank')
    },
    changeReverse(row, e) {
      // this.$confirm('Are you sure change match reverse status?').then((res) => {
      updateReverse({
        thirdMatchId: row.thirdMatchId,
        matchType: this.matchType,
        reverse: e
      })
        .then((res) => {
          if (res.code === 0) {
            this.$message({
              message: 'success',
              type: 'success'
            })
          }
        })
        .finally((e) => {
          this.getList()
        })
      // }).catch(e => {
      //   row.reverse = e ? 0: 1
      // })
    },
    changeIgnore(row, e) {
      updateIgnore({
        thirdMatchId: row.thirdMatchId,
        matchType: this.matchType,
        ignore: e
      }).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: 'success',
            type: 'success'
          })
        }
        this.getList()
      })
    },
    marketMappingFn(row) {
      const { href } = this.$router.resolve({
        path: '/market-mapping',
        query: { matchId: row.sourceMatchId, info: JSON.stringify(row) }
      })
      window.open(href, '_blank')
    },
    handleClick(tab, event) {
      this.queryData.current = 1
      this.getList(this.activeName)
    },
    createMatch(row) {
      if (row.standardMatchId) {
        this.$message.error('Match mapped!')
        return
      }
      this.gridData = []
      getStandardMatch(row.thirdMatchId).then((res) => {
        if (res.code === 0 && res.data) {
          this.gridData.push(res.data)
          this.dialogTableVisible = true
          this.gridData.push(row)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    create() {
      let data = this.gridData[0]
      data.thirdMatchId = this.gridData[1].thirdMatchId
      data.matchFormat = this.gridData[1].matchFormat
      createStandardMatchFromThirdMatch(data).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: 'success',
            type: 'success'
          })
          this.getList()
          this.dialogTableVisible = false
        }
      })
    },
    thirdCompared(standard, thirdData, dataSourceCode) {
      if (
        String(standard).replace(/(^\s*)|(\s*$)/g, '') !==
        String(thirdData).replace(/(^\s*)|(\s*$)/g, '')
      ) {
        return 'color:red'
      }
    },
    changePage(e) {
      if (this.dateRange) {
        this.queryData.current = e
        this.getList()
      } else {
        this.$message.error('Must select a time range')
        this.list = []
        this.total = 0
      }
    },
    async edit(row, name) {
      this.type = name
      const data = {}
      if (name === 'Home' || name === 'Away') {
        await queryThirdTeamByIds([
          name === 'Home' ? row.sourceHomeId : row.sourceAwayId
        ]).then((res) => {
          if (res.code === 0) {
            ;(data.thirdId = res.data[0].id),
              (data.standardId = res.data[0].standardTeamId || ''),
              (data.standardName = res.data[0].standardTeamName),
              (data.standardRegionId = res.data[0].standardRegionId || ''),
              (data.standardRegionName = res.data[0].standardRegionName),
              (data.standardTournamentId =
                res.data[0].standardTournamentId || ''),
              (data.standardTournamentName =
                res.data[0].standardTournamentName ||
                (res.data[0].standardTournamentId === null
                  ? ''
                  : `selected TournamentID:${res.data[0].standardTournamentId}`)),
              (data.thirdName = res.data[0].name),
              (data.standardSportId = res.data[0].standardSportId || ''),
              (data.standardSportsName = res.data[0].standardSportName)
            const info = res.data[0]
            data.dataSourceCode = info.dataSourceCode
            data.sourceId = info.sourceTeamId
          }
        })
      }
      if (name === 'Tournament') {
        await queryThirdTournamentBySourceIds([row.sourceTournamentId]).then(
          (res) => {
            if (res.code === 0) {
              const  {sourceSportId, dataSourceCode} =  row
              const list = res.data.filter(item => {
                return item.sourceSportId  === `${dataSourceCode}:${sourceSportId}`
              })
              ;(data.thirdId = list[0].id),
                (data.standardId = list[0].standardTournamentId || ''),
                (data.standardName = list[0].standardTournamentName),
                (data.thirdName = list[0].name),
                (data.sportId = list[0].standardSportId || ''),
                (data.standardSportsName = list[0].standardSportName || ''),
                (data.regionId = list[0].standardRegionId || ''),
                (data.regionName = list[0].standardRegionName)
              const info = list[0]
              data.dataSourceCode = info.dataSourceCode
              data.sourceId = info.sourceTournamentId
            }
          }
        )
      }
      if (name === 'Region') {
        await queryThirdRegionBySourceIds([row.sourceRegionId]).then((res) => {
          if (res.code === 0) {
            ;(data.thirdId = res.data[0].id),
              (data.standardId = res.data[0].standardRegionId || ''),
              (data.standardName = res.data[0].standardRegionName),
              (data.thirdName = res.data[0].name)
            const info = res.data[0]
            data.dataSourceCode = info.dataSourceCode
            data.sourceId = info.sourceRegionId
          }
        })
      }
      if (name === 'Sport') {
        return
        // await queryThirdSportTypeBySourceIds([row.sourceSportId]).then(res => {
        //   if (res.code === 0) {
        //     data.thirdId= res.data[0].id,
        //     data.standardId= res.data[0].standardSportId || '',
        //     data.standardName= res.data[0].standardSportName,
        //     data.thirdName= res.data[0].sportName
        //   }
        // })
      }
      this.dialogEdit(data)
    },
    async dialogSendData() {
      let data
      try {
        if (this.type === 'Sport') {
          data = await editSportMapping(this.dialogForm)
        }
        if (this.type === 'Region') {
          data = await editRegionMapping(this.dialogForm)
        }
        if (this.type === 'Tournament') {
          data = await editTournamentMapping(this.dialogForm)
        }
        if (this.type === 'Home' || this.type === 'Away') {
          data = await editTeamMapping(this.dialogForm)
        }
        // data = await this.editData(this.dialogForm)
        if (data.code === 0) {
          this.getList && this.getList()
          this.dialogHide()
          this.$message({
            message: 'Successful operation',
            type: 'success'
          })
        } else {
          this.dialog.isLoading = false
        }
      } catch (error) {
        this.dialog.isLoading = false
      }
    },
    onSelectedDrug(e, item) {
      this.dialogForm.standardMatchId = null
      this.dialogForm.standardName = null
    },
    tableRowClassName({ row }) {
      if (row.mappingStatus === 2) {
        return 'cancel'
      }
    },
    inputBlur(index, row) {
      if (!row.standardMatchId) {
        return
      }
      this.$confirm('confirm modification?', '', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      }).then(() => {
        findMatchInfoCount(row.standardMatchId).then((res) => {
          if (res.code === 0) {
            let data = {
              thirdMatchId: row.thirdMatchId,
              standardMatchId: row.standardMatchId,
              remark:
                typeof this.userInfo == 'object'
                  ? this.userInfo.name
                  : JSON.parse(this.userInfo).name
            }
            updateSetMatchInfo(data).then((res) => {
              if (res.code === 0) {
                this.$message({
                  type: 'success',
                  message: 'success'
                })
                this.getList()
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message
            })
            this.getList()
          }
        })
      })
    },
    switchHandle(data) {
      // console.log(data)
      const targetCheckStatus = data.switchStatus ? 2 : 1
      eidtMatchMappingCheckStatus(data.thirdMatchId, targetCheckStatus).then(
        (res) => {
          this.$message({
            message: 'Successful operation',
            type: 'success'
          })
          let index = this.list.findIndex(
            (item) => item.thirdMatchId === data.thirdMatchId
          )
          this.list.splice(
            index,
            1,
            Object.assign({}, this.list[index], {
              checkStatus: targetCheckStatus,
              switchStatus: targetCheckStatus === 1
            })
          )
        }
      )
    },
    getList(activeName) {
      let result = this.functionInfoList.find((item) => {
        return (
          item.privilegeMark === 'TMC/Page/match-mapping/MatchID' &&
          item.featureStatus !== 3
        )
      })
      this.loading = true
      const extraData = JSON.parse(JSON.stringify(this.extraData)) || {}
      if (!activeName) {
        activeName = this.activeName
      }
      if (extraData.dataSourceCodes.length !== 0) {
        if (activeName && extraData.dataSourceCodes.indexOf(+activeName) > -1) {
          extraData.dataSourceCodes = [+activeName]
        } else if (+this.activeName !== 0) {
          this.loading = false
          return (this.list = [])
        }
      } else {
        extraData.dataSourceCodes = +activeName ? [+activeName] : []
      }
      const { startTime, endTime, matchQueryType } = this.extraData
      if (!(matchQueryType || (startTime && endTime))) {
        return this.$message.error('Must select a time range')
      }
      this.getData({
        ...this.queryData,
        ...this.fliterParams(extraData, ['sourceMatchId']),
        matchType: this.matchType
      })
        .then((res) => {
          const data = res.data
          if (res.code === 0) {
            let list = data.records
            list = list.map((item) => ({
              ...item,
              matchIdStatus: result ? true : false,
              switchStatus: item.checkStatus === 1
            }))

            this.list = list
            this.total = data.total
            this.loading = false
            let allTotal = 0
            getMappingThirdMatchCounter({
              ...this.queryData,
              ...this.extraData,
              matchType: this.matchType
            }).then((res) => {
              if (res.code === 0) {
                res.data.forEach((e) => {
                  this.tabsList.forEach((item) => {
                    if (e.dataSourceCode === +item.value) {
                      item.total = e.count
                    }
                  })
                  allTotal += e.count
                })
                this.tabsList[0].total = allTotal
              }
            })
          }
        })
        .catch((err) => {
          this.list = []
          this.loading = false
        })
    },
    cellEnterHover(data) {
      const { thirdMatchId, getUnMappedErrorStatus = false } = data
      if (!thirdMatchId) {
        return
      }
      if (!getUnMappedErrorStatus) {
        this.timerGroup[thirdMatchId] = setTimeout(() => {
          getMatchHighlight(thirdMatchId).then((res) => {
            const {
              awayMapped,
              homeMapped,
              regionMapped,
              tournamentMapped,
              sportsMapped
            } = res.data
            const targetIndex = this.list.findIndex(
              (item) => item.thirdMatchId === thirdMatchId
            )
            if (targetIndex !== -1) {
              this.list.splice(
                targetIndex,
                1,
                Object.assign({}, this.list[targetIndex], {
                  awayMappedStatus: awayMapped,
                  homeMappedStatus: homeMapped,
                  regionMappedStatus: regionMapped,
                  tournamentMappedStatus: tournamentMapped,
                  sportMappedStatus: sportsMapped,
                  getUnMappedErrorStatus: true
                })
              )
            }
          })
        }, 1000)
      }
    },
    cellLeaveHover(data) {
      const { thirdMatchId } = data
      if (!thirdMatchId) {
        return
      }
      if (this.timerGroup[thirdMatchId]) {
        clearTimeout(this.timerGroup[thirdMatchId])
      }
    },
    getMappingErrorData() {},
    getData,
    parseTime,
    before(data) {
      if (data.standardMatchId) {
        this.$message.error('Match mapped!')
        return
      }
      this.$msgbox({
        title: 'Warning',
        type: 'warning',
        message: `Confirm to modify this Again Mapping?`,
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel'
      }).then((action) => {
        this.outPut(data.thirdMatchId)
      })
    },
    outPut(thirdMatchId) {
      againMapping(thirdMatchId).then((res) => {
        this.getList()
      })
    },
    dateRangeFn(val, type) {
      if (this.extraData.matchQueryType) {
        this.extraData.matchQueryType = ''
      }
      if (!val) {
        this.extraData.startTime = ''
        this.extraData.endTime = ''
      } else {
        const [start, end] = val
        this.extraData.startTime = start
        this.extraData.endTime = end
      }
      if (type === 7) {
        this.extraData.startTime = ''
        this.extraData.endTime = +new Date()
        this.extraData.matchQueryType = 6
      }
      this.$nextTick(() => {
        this.search()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.matchTips {
  color: red;
  font-weight: bold;
}
/deep/ .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
.check-status-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch {
    margin-right: 10px;
  }
}
/deep/.el-table .cancel {
  background: #00000024 !important;
}
.cursor {
  cursor: pointer;
}
.red {
  /deep/ .el-input__inner {
    border: 1px solid red;
  }
}
.blue {
  /deep/ .el-input__inner {
    border: 1px solid blue;
  }
}
</style>